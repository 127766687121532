import { hasComplementsToUpload } from 'APP/components/FileUpload/FileUploadHelpers';

import { ApplicantHelpers } from './helpers/ApplicantHelpers';
import { ApplicationHelpers } from './helpers/ApplicationHelpers';
import MortgageHelpers from './helpers/MortgageHelpers';
import {
    ApplicationInfo,
    BankIdProgress,
    FormFieldInteractionPayload,
    IApplicationCompanyType,
    IApplicationInfo,
    IContentInfo,
    IDataLayerBase,
    IElementInteractionInfo,
    IEventInfo,
    IEventObject,
    IFlowInfo,
    IFormInfo,
    IMessagePromtClick,
    IModalInfo,
    InteractionInfo,
    IOfferImpression,
    IOfferInfo,
    IOfferLinkClick,
    IOfferListShowDetails,
    IOtherPartnersPromptClick,
    IPageInfo,
    IPushFileUpload,
    IPushFlowStepImpression,
    IPushUpsellProductAcceptPayload,
    IPushUpsellProductViewedPayload,
    IUserInfo,
} from './interfaces/analytics.interfaces';
import { IApplication } from './interfaces/applications.interfaces';
import { BizApplication } from './interfaces/business/application';
import { BizOffer } from './interfaces/feature/business/offers.interfaces';
import { IOffer, IWithdrawnOffer } from './interfaces/private/offers.interfaces';

const events = {
    LOGIN: 'login',
    DYNAMIC_PAGE_VIEW: 'dynamicPageview',
    FLOW_STEP_IMPRESSION: 'flowStepImpression',
    EXPLAINER_CLICKED: 'explainerClicked',
    VIEW_APPLICATION_CLICK: 'viewApplicationClick',
    UI_INTERACTION: 'UIInteraction',
    ELEMENT_INTERACTION: 'elementInteraction',
    OFFER_CLICK: 'offerClick',
    OFFER_ACCEPTED: 'offerAccepted',
    OFFER_DETAILS_EXPANDED: 'offerDetailsExpanded',
    OFFER_LIST_IMPRESSION: 'offerListImpression',
    OFFER_LIST_SORT: 'offerListSort',
    OFFER_IMPRESSION: 'offerImpression',
    OFFER_EXPAND: 'yourOfferExpand',
    OFFER_COLLAPSE: 'yourOfferCollapse',
    OFFER_LINK_CLICK: 'yourOfferLinkClick',
    SIGN_AT_BANKED_CLICKED: 'signAtBankClicked',
    MODAL_SHOWN: 'modalShown',
    MODAL_DISMISSED: 'modalDismissed',
    MODAL_CONFIRMED: 'modalConfirmed',
    CONTENT_CLICK: 'contentClick',
    CONSENT_INTERACTION: 'consentInteraction',
    FORM_STEP_IMPRESSION: 'formStepImpression',
    FORM_STEP_SUBMIT: 'formStepSubmit',
    FORM_FIELD_INTERACTION: 'formFieldInteraction',
    FORM_FIELD_ERROR: 'formFieldError',
    UPSELL_PRODUCT_VIEW: 'upsellProductView',
    UPSELL_PRODUCT_ACCEPT: 'upsellProductAccept',
    FILE_UPLOAD: 'fileUpload',
    REVISION_SUBMITTED: 'revisionSubmitted',
    REVISION_REJECTED: 'revisionRejected',
    REVISION_PROMPT_IMPRESSION: 'revisionPromptImpression',
    REVISION_PROMPT_CLICK: 'revisionPromptClick',
    REVISION_CREATED: 'revisionCreated',
    WITHDRAWN_OFFER_IMPRESSION: 'withdrawnOfferImpression',
    AUTOGIRO_ACCEPT: 'autogiroAccept', // delete when inbox is moved to monorepo
    YOUR_INSURANCE_EXPAND: 'yourInsuranceExpand',
    YOUR_INSURANCE_COLLAPSE: 'yourInsuranceCollapse',
    YOUR_INSURANCE_LINK_CLICK: 'yourInsuranceLinkClick',
    MESSAGE_PROMPT_CLICK: 'messagesPromptClick',
    OFFER_LIST_SHOW_DETAILS: 'offerListShowDetails',
    OTHER_PARTNERS_PROMPT_CLICK: 'otherPartnersPromptClick',
    CUSTOMER_DATA: 'customer_data',
};

/**
 * Pushes an event to the data layer.
 * Assigns the data layer to an empty array if it's an array.
 */
function pushToDL(payload: IDataLayerBase): void {
    if (!Array.isArray(window.dataLayer)) {
        window.dataLayer = [];
    }

    window.dataLayer.push(payload);
}

function getBusinessStructureName(application: IApplicationCompanyType): string | undefined {
    if (application.company) {
        switch (application.company.type) {
            case 'EN':
                return 'enskild firma';
            case 'AB':
                return 'aktiebolag';
            case 'HB':
                return 'handelsbolag';
            default:
                return undefined;
        }
    }

    return undefined;
}

export const Analytics = {
    /**
     * This event should be pushed whenever the user is exposed to the option of choosing an insurance.
     */
    pushUpsellProductView(payload: IPushUpsellProductViewedPayload): void {
        const { applicationInfo, offerInfo, eventInfo } = payload;
        const obj: IEventObject = {
            event: events.UPSELL_PRODUCT_VIEW,
            applicationInfo,
            offerInfo,
            eventInfo: {
                upsellProduct: eventInfo?.upsellProduct,
                context: eventInfo?.context,
            },
        };
        pushToDL(obj);
    },
    /**
     * This event should trigger on the receipt page, if an offer includes a loan or safety insurance.
     * Meaning it should not trigger when just adding an insurance to the offer since this does not mean that the user actually purschased the insurance.
     */
    pushUpsellProductAccept(payload: IPushUpsellProductAcceptPayload): void {
        const { applicationInfo, offerInfo, eventInfo } = payload;
        const obj: IEventObject = {
            event: events.UPSELL_PRODUCT_ACCEPT,
            applicationInfo,
            offerInfo,
            eventInfo: {
                upsellProduct: eventInfo?.upsellProduct,
                context: eventInfo?.context,
            },
        };
        pushToDL(obj);
    },

    pushFileUpload(payload: IPushFileUpload): void {
        const { applicationInfo, offerInfo, hasFiles } = payload;
        const obj = {
            event: events.FILE_UPLOAD,
            applicationInfo,
            offerInfo,
            eventInfo: {
                hasUploadedFiles: hasFiles,
            },
        };

        pushToDL(obj);
    },

    pushFileUploadFlow(payload: { event: string; flowInfo?: IFlowInfo }): void {
        const { event, flowInfo } = payload;

        pushToDL({
            event,
            ...(flowInfo && { flowInfo }),
        });
    },

    pushUserInfo(payload: IUserInfo): void {
        const {
            id,
            activeApplication,
            activeApplicationPrivate,
            activeApplicationBusiness,
            activeApplicationMortgage,
            activeApplicationLoanPromise,
        } = payload;

        pushToDL({
            event: events.CUSTOMER_DATA,
            userInfo: {
                id,
                activeApplication,
                activeApplicationPrivate,
                activeApplicationBusiness,
                activeApplicationMortgage,
                activeApplicationLoanPromise,
            },
        });
    },

    pushUserLogin(payload: IEventInfo): void {
        const { method } = payload;

        pushToDL({
            event: events.LOGIN,
            eventInfo: { method },
        });
    },

    pushBankIdProgress(event: BankIdProgress, payload: IEventInfo): void {
        const { context, method, reason } = payload;

        pushToDL({
            event,
            eventInfo: { context, method, reason },
        });
    },

    /**
     * Pushes an event indicating that the user loaded a page.
     */
    pushPageView(payload: IPageInfo): void {
        const { product, location, title, trackingId, path, msg_medium, msg_action, msg_template } = payload;

        pushToDL({
            event: events.DYNAMIC_PAGE_VIEW,
            pageInfo: {
                product,
                location,
                path,
                title,
                trackingId,
                msg_medium,
                msg_action,
                msg_template,
            },
        });
    },

    pushFlowStepImpression(payload: IPushFlowStepImpression): void {
        const { name, step, applicationInfo, offerInfo } = payload;

        pushToDL({
            event: events.FLOW_STEP_IMPRESSION,
            flowInfo: { name, step },
            applicationInfo,
            offerInfo,
        });
    },

    pushOfferExpanded(payload: IPushFlowStepImpression): void {
        const { applicationInfo, offerInfo } = payload;

        pushToDL({
            event: events.OFFER_EXPAND,
            applicationInfo,
            offerInfo,
        });
    },

    pushOfferCollapsed(payload: IPushFlowStepImpression): void {
        const { applicationInfo, offerInfo } = payload;

        pushToDL({
            event: events.OFFER_COLLAPSE,
            applicationInfo,
            offerInfo,
        });
    },

    pushExplainerClicked(payload: { explainerName: string }): void {
        const { explainerName } = payload;

        pushToDL({
            event: events.EXPLAINER_CLICKED,
            eventInfo: { explainerName },
        });
    },

    pushUIInteraction(payload: InteractionInfo): void {
        const { type, category, title } = payload;
        pushToDL({
            event: events.UI_INTERACTION,
            interactionInfo: { type, category, title },
        });
    },

    pushElementInteraction(payload: IElementInteractionInfo): void {
        const { eventInfo, elementInfo } = payload;
        const { interaction, institutionId } = eventInfo;
        const { id, group, url } = elementInfo;
        pushToDL({
            event: events.ELEMENT_INTERACTION,
            elementInfo: { id, group, url },
            eventInfo: { interaction, institutionId },
        });
    },

    pushViewApplicationClick(payload: { applicationInfo: IApplicationInfo }): void {
        const { applicationInfo } = payload;

        pushToDL({
            event: events.VIEW_APPLICATION_CLICK,
            applicationInfo,
        });
    },

    pushOfferClick(payload: { applicationInfo: IApplicationInfo; offerInfo: IOfferInfo }): void {
        const { applicationInfo, offerInfo } = payload;

        pushToDL({
            event: events.OFFER_CLICK,
            applicationInfo,
            offerInfo,
        });
    },

    pushOfferDetailsExpanded(payload: { applicationInfo: IApplicationInfo; offerInfo: IOfferInfo }): void {
        const { applicationInfo, offerInfo } = payload;

        pushToDL({
            event: events.OFFER_DETAILS_EXPANDED,
            applicationInfo,
            offerInfo,
        });
    },

    pushYourInsuranceExpanded(payload: {
        applicationInfo: IApplicationInfo;
        offerInfo: IOfferInfo;
        eventInfo: IEventInfo;
    }): void {
        const { applicationInfo, offerInfo, eventInfo } = payload;

        pushToDL({
            event: events.YOUR_INSURANCE_EXPAND,
            applicationInfo,
            eventInfo,
            offerInfo,
        });
    },

    pushYourInsuranceCollapsed(payload: {
        applicationInfo: IApplicationInfo;
        offerInfo: IOfferInfo;
        eventInfo: IEventInfo;
    }): void {
        const { applicationInfo, eventInfo, offerInfo } = payload;

        pushToDL({
            event: events.YOUR_INSURANCE_COLLAPSE,
            applicationInfo,
            eventInfo,
            offerInfo,
        });
    },

    pushYourInsuranceLinkClicked(payload: IOfferLinkClick): void {
        const { applicationInfo, elementInfo, offerInfo, eventInfo } = payload;
        pushToDL({
            event: events.YOUR_INSURANCE_LINK_CLICK,
            applicationInfo,
            elementInfo,
            offerInfo,
            eventInfo,
        });
    },

    pushOfferAccepted(payload: {
        applicationInfo: IApplicationInfo;
        offerInfo: IOfferInfo;
        loanInsuranceAccepted?: boolean;
        safetyInsuranceAccepted?: boolean;
        signedWithBankId?: boolean;
    }): void {
        const { applicationInfo, offerInfo, loanInsuranceAccepted, safetyInsuranceAccepted, signedWithBankId } =
            payload;

        pushToDL({
            event: events.OFFER_ACCEPTED,
            applicationInfo,
            offerInfo,
            eventInfo: {
                loanInsuranceAccepted,
                safetyInsuranceAccepted,
                signedWithBankId,
            },
        });
    },

    pushSignAtBankClicked(payload: { applicationInfo: IApplicationInfo; offerInfo: IOfferInfo }): void {
        const { applicationInfo, offerInfo } = payload;

        pushToDL({
            event: events.SIGN_AT_BANKED_CLICKED,
            applicationInfo,
            offerInfo,
        });
    },

    pushModalShown(payload: IModalInfo): void {
        const { category, name } = payload;

        pushToDL({
            event: events.MODAL_SHOWN,
            modalInfo: {
                category,
                name,
            },
        });
    },

    pushModalDismissed(payload: IModalInfo): void {
        const { name, category, method } = payload;

        pushToDL({
            event: events.MODAL_DISMISSED,
            modalInfo: { name, category },
            eventInfo: { method },
        });
    },

    pushModalConfirmed(payload: IModalInfo): void {
        const { name, category } = payload;

        pushToDL({
            event: events.MODAL_CONFIRMED,
            modalInfo: { name, category },
        });
    },

    pushContentClick(payload: IContentInfo): void {
        const { product, title, list, position, id } = payload;

        pushToDL({
            event: events.CONTENT_CLICK,
            contentInfo: {
                product,
                title,
                list,
                position,
                id,
            },
        });
    },

    pushConsentInteraction(payload: IContentInfo): void {
        const { action, label } = payload;

        pushToDL({
            event: events.CONSENT_INTERACTION,
            contentInfo: { action, label },
        });
    },

    /**
     * Pushes an event indicating that the user interacted with a form element
     * @param {Object} payload
     * @param {string} payload.formName The name of the form
     * @param {number} payload.formStep The current step.
     * @param {string} payload.fieldName True if the submit was successful, false if not.
     * @param {'button' | 'input' | 'checkbox' | 'slider'} payload.fieldType
     * @param {string | number | boolean} [payload.fieldValue]
     * @param {string} payload.fieldInteraction
     * @param {string} payload.variation The name of the form variation. The bank name is an appropriate value
     */
    pushFormFieldInteraction(payload: FormFieldInteractionPayload): void {
        const {
            formName,
            stepNumber,
            fieldName,
            fieldType,
            fieldValue,
            fieldInteraction,
            variation,
            stepName,
            product,
        } = payload;
        pushToDL({
            event: events.FORM_FIELD_INTERACTION,
            formInfo: {
                name: formName,
                stepNumber: stepNumber,
                variation: variation,
                stepName: stepName,
                product: product,
            },
            formFieldInfo: {
                name: fieldName,
                type: fieldType,
                ...(fieldValue !== undefined && fieldValue !== null && { value: fieldValue }),
            },
            eventInfo: { interaction: fieldInteraction },
        });
    },

    /**
     *
     * this is the new event for the form field interaction including stepNumber and stepName.
     *
     * Pushes an event indicating that the user interacted with a form element.
     * @param {Object} payload
     * @param {string} payload.name The name of the form
     * @param {number} payload.stepNumber The current step.
     * @param {string} payload.stepName The name of current step.
     * @param {string} payload.fieldName True if the submit was successful, false if not.
     * @param {'button' | 'input' | 'checkbox' | 'slider'} payload.fieldType
     * @param {string | number | boolean} [payload.fieldValue]
     * @param {string} payload.fieldInteraction
     * @param {string} payload.variation The name of the form variation. The bank name is an appropriate value
     */
    pushFormFieldInteractionNew(payload: FormFieldInteractionPayload): void {
        const { form, stepNumber, stepName, fieldName, fieldType, fieldValue, fieldInteraction, variation } = payload;
        pushToDL({
            event: events.FORM_FIELD_INTERACTION,
            formInfo: { name: form, stepNumber: stepNumber, stepName: stepName, variation: variation },
            formFieldInfo: {
                name: fieldName,
                type: fieldType,
                ...(fieldValue !== undefined && fieldValue !== null && { value: fieldValue }),
            },
            eventInfo: { interaction: fieldInteraction },
        });
    },

    /**
     * Pushes an event indicating that the user caused an error at a form element.
     * @param {Object} payload
     * @param {string} payload.formName The name of the form
     * @param {number} payload.formStep The current step.
     * @param {string} payload.fieldName True if the submit was successful, false if not.
     * @param {'button' | 'input' | 'checkbox'} payload.fieldType
     * @param {string}[ payload.fieldValue]
     * @param {string} payload.errorCode
     * @param {string} payload.errorMessage
     * @param {string} payload.variation The name of the form variation. The bank name is an appropriate value
     */
    pushFormFieldError(payload: FormFieldInteractionPayload): void {
        const {
            formName,
            fieldName,
            fieldType,
            fieldValue,
            errorMessage,
            errorCode,
            variation,
            stepName,
            stepNumber,
            product,
        } = payload;
        pushToDL({
            event: events.FORM_FIELD_ERROR,
            formInfo: { name: formName, stepNumber: stepNumber, variation: variation, stepName: stepName, product },
            formFieldInfo: { name: fieldName, type: fieldType, ...(fieldValue && { value: fieldValue }) },
            messageInfo: { code: errorCode, message: errorMessage },
        });
    },

    /**
     *
     * this is the new event for the form field error including stepNumber and stepName.
     *
     * Pushes an event indicating that the user caused an error at a form element.
     * @param {Object} payload
     * @param {string} payload.name The name of the form
     * @param {number} payload.stepNumber The current step.
     * @param {string} payload.stepName The current step.
     * @param {string} payload.fieldName True if the submit was successful, false if not.
     * @param {'button' | 'input' | 'checkbox'} payload.fieldType
     * @param {string}[ payload.fieldValue]
     * @param {string} payload.errorCode
     * @param {string} payload.errorMessage
     * @param {string} payload.variation The name of the form variation. The bank name is an appropriate value
     */
    pushFormFieldErrorNew(payload: FormFieldInteractionPayload): void {
        const { name, stepNumber, stepName, fieldName, fieldType, fieldValue, errorMessage, errorCode, variation } =
            payload;
        pushToDL({
            event: events.FORM_FIELD_ERROR,
            formInfo: { name: name, stepNumber: stepNumber, stepName: stepName, variation: variation },
            formFieldInfo: { name: fieldName, type: fieldType, ...(fieldValue && { value: fieldValue }) },
            messageInfo: { code: errorCode, message: errorMessage },
        });
    },

    /**
     * Pushes an event indicating that the user has submitted the form.
     * @param {Object} payload
     * @param {string} payload.formName The name of the form
     * @param {number} payload.stepNumber The current step.
     * @param {number} payload.stepName The current step name.
     * @param {string} payload.variation The name of the form variation. The bank name is an appropriate value
     * @param {boolean} payload.success True if the submit was successful, false if not.
     */
    pushFormStepSubmit(payload: IFormInfo): void {
        const { formName, stepNumber, success, stepName, variation, product } = payload;
        pushToDL({
            event: events.FORM_STEP_SUBMIT,
            formInfo: {
                name: formName ?? '',
                stepNumber: stepNumber,
                stepName: stepName,
                variation: variation,
                product,
            },
            eventInfo: { success },
        });
    },

    /**
     * Pushes an event indicating that the user has reached a step in the form.
     * @param {Object} payload
     * @param {string} payload.formName The name of the form
     * @param {number} payload.formStep The current step.
     * @param {string} payload.variation The name of the form variation. The bank name is an appropriate value
     * @param {Object} [data={}] Data that will be sent to sentry.
     */
    pushFormStepImpression(payload: IFormInfo): void {
        const { formName, formStep, variation, stepName, product } = payload;
        pushToDL({
            event: events.FORM_STEP_IMPRESSION,
            formInfo: { name: formName ?? '', stepNumber: formStep, stepName: stepName, variation: variation, product },
        });
    },

    /**
     *
     * This event is the new form step impression event that includes stepNumber and stepName.
     *
     * Pushes an event indicating that the user has reached a step in the form.
     * @param {Object} payload
     * @param {string} payload.name The name of the form
     * @param {string} payload.stepName The name of the step
     * @param {number} payload.stepNumber The current step.
     * @param {string} payload.variation The name of the form variation. The bank name is an appropriate value
     * @param {Object} [data={}] Data that will be sent to sentry.
     */
    pushFormStepImpressionNew(payload: IFormInfo): void {
        const { name, stepNumber, stepName, variation } = payload;
        pushToDL({
            event: events.FORM_STEP_IMPRESSION,
            formInfo: { name: name, stepNumber: stepNumber, stepName: stepName, variation: variation },
        });
    },

    /**
     * Pushes an event indicating how many offers the application has
     */
    pushOfferListImpression(payload: {
        hasOffers: boolean;
        list?: 'offerList' | 'loanPromiseList';
        applicationInfo?: IApplicationInfo;
    }): void {
        const { hasOffers, list, applicationInfo } = payload;
        pushToDL({
            event: events.OFFER_LIST_IMPRESSION,
            eventInfo: { hasOffers, list },
            applicationInfo,
        });
    },
    /**
     * Pushes an event indicating if user have seen the withdrawn offer list
     */
    pushWithdrawnOfferListImpression(payload: {
        applicationInfo?: IApplicationInfo;
        numberOfWithdrawals: number;
        offerInfo: IOfferInfo;
    }): void {
        const { applicationInfo, numberOfWithdrawals, offerInfo } = payload;
        pushToDL({
            event: events.WITHDRAWN_OFFER_IMPRESSION,
            eventInfo: { numberOfWithdrawals },
            applicationInfo,
            offerInfo,
        });
    },

    /**
     * Pushes an event indicating the revision request succeeded with the old application ID
     */
    pushRevisionSubmitted(payload: IApplicationInfo): void {
        const {
            applicationId,
            product,
            amortizeLength,
            amount,
            amountToSolve,
            hasAddedCoApplicant,
            featureSmartRevision,
        } = payload;
        pushToDL({
            event: events.REVISION_SUBMITTED,
            eventInfo: {
                featureSmartRevision,
            },
            applicationInfo: {
                amortizeLength: amortizeLength,
                amount: amount,
                amountToSolve: amountToSolve,
                id: applicationId,
                product: product,
                hasCoApplicant: hasAddedCoApplicant,
            },
        });
    },
    /**
     * Pushes an event indicating the revision request succeeded with the new application ID
     */
    pushRevisionCreated(payload: IApplicationInfo): void {
        const {
            applicationId,
            product,
            amortizeLength,
            amount,
            amountToSolve,
            hasAddedCoApplicant,
            featureSmartRevision,
        } = payload;
        pushToDL({
            event: events.REVISION_CREATED,
            eventInfo: {
                featureSmartRevision,
            },
            applicationInfo: {
                amortizeLength: amortizeLength,
                amount: amount,
                amountToSolve: amountToSolve,
                id: applicationId,
                product: product,
                hasCoApplicant: hasAddedCoApplicant,
            },
        });
    },
    /**
     * Pushes an event when user see confirmation page
     */
    pushRevisionConfirmation(payload: IApplicationInfo): void {
        const { applicationId, product, amortizeLength, amount, amountToSolve, stepName, stepNumber } = payload;
        pushToDL({
            event: events.REVISION_SUBMITTED,
            applicationInfo: {
                amortizeLength: amortizeLength,
                amount: amount,
                amountToSolve: amountToSolve,
                id: applicationId,
                product: product,
                stepName: stepName,
                stepNumber: stepNumber,
            },
        });
    },

    /**
     * Pushes an event indicating the revision request failed
     */
    pushRevisionRejected(payload: IApplicationInfo): void {
        const {
            applicationId,
            product,
            errorCode,
            errorMessage,
            amortizeLength,
            amount,
            amountToSolve,
            hasAddedCoApplicant,
            featureSmartRevision,
        } = payload;
        pushToDL({
            event: events.REVISION_REJECTED,
            eventInfo: {
                featureSmartRevision,
            },
            applicationInfo: {
                amortizeLength: amortizeLength,
                amount: amount,
                amountToSolve: amountToSolve,
                id: applicationId,
                product: product,
                hasCoApplicant: hasAddedCoApplicant,
            },
            messageInfo: {
                code: errorCode,
                text: errorMessage,
            },
        });
    },

    /**
     * Pushes an event indicating the revision CTA has been exposed to the user
     */
    pushRevisionPromptImpression(payload: { applicationInfo?: IApplicationInfo; eventInfo: IEventInfo }): void {
        const { applicationInfo, eventInfo } = payload;
        pushToDL({
            event: events.REVISION_PROMPT_IMPRESSION,
            applicationInfo,
            eventInfo,
        });
    },
    /**
     * Pushes an event indicating the revision CTA has been clicked
     */
    pushRevisionPromptClick(payload: { applicationInfo?: IApplicationInfo; eventInfo: IEventInfo }): void {
        const { applicationInfo, eventInfo } = payload;
        pushToDL({
            event: events.REVISION_PROMPT_CLICK,
            applicationInfo,
            eventInfo,
        });
    },

    createApplicationInfo(application: IApplication | BizApplication, overrides = {}): ApplicationInfo {
        const isMortgageApplication = ApplicationHelpers.isMortgageApplication(application as IApplication);

        if (isMortgageApplication) {
            // MORTGAGE
            const purpose = (application as IApplication).mortgage_purpose;
            const hasCoApplicant = ApplicantHelpers.hasCoApplicant((application as IApplication).applicants);

            return {
                id: application.id,
                purpose,
                amount: MortgageHelpers.getTotalLoanAmount(application),
                product: application.type,
                subProduct: `${purpose} mortgage`,
                hasCoApplicant,
                businessStructure: getBusinessStructureName(application as IApplicationCompanyType),
                ...overrides,
            };
        } else {
            // PRIVATE & BUSINESS
            return {
                id: application.id,
                amount: application.products[0].total_loan_amount,
                product: application.type,
                businessStructure: getBusinessStructureName(application as IApplicationCompanyType),
                ...overrides,
            };
        }
    },

    createOfferInfo(
        offer: IOffer | BizOffer,
        list?: string,
        position?: number,
        includesLoanInsurance?: boolean,
        includesSafetyInsurance?: boolean,
        isCurrentRevision?: boolean
    ): IOfferInfo {
        return {
            id: offer.id,
            bankName: offer.product.name,
            partnerId: offer.product.partner_information.id,
            amount: offer.amount,
            list,
            position,
            includesLoanInsurance,
            includesSafetyInsurance,
            includesUploadComplements: hasComplementsToUpload(offer.complements),
            isCurrentRevision,
        };
    },
    createWithDrawnOfferInfo(withDrawnOffer: IWithdrawnOffer[]): IOfferInfo[] {
        const withDrawnOffers = withDrawnOffer.map(offer => {
            return {
                id: offer.offer.id,
                bankName: offer.offer.product.name,
                partnerId: offer.offer.product.partner_information.id,
                amount: offer.offer.amount,
                includesUploadComplements: false,
            };
        });
        return withDrawnOffers;
    },

    createUserInfo(
        id: string,
        returning: string | undefined,
        hasActiveApplication: boolean,
        hasOffers: boolean
    ): {
        id: string | undefined;
        returning: string | undefined;
        hasActiveApplication: boolean;
        hasOffers: boolean;
    } {
        return {
            id, // Customer ID from the database - a unique identifier of the user across all markets. If the user is logged out, this should be set to undefined.
            returning, // Whether this user is returning or not. The only allowed values are 'yes', 'no' and undefined.
            hasActiveApplication,
            hasOffers,
        };
    },

    pushOfferImpression(payload: IOfferImpression): void {
        const { offerInfo, applicationInfo, eventInfo } = payload;
        pushToDL({
            event: events.OFFER_IMPRESSION,
            applicationInfo,
            offerInfo,
            eventInfo,
        });
    },
    pushOfferListSort(payload: IOfferImpression): void {
        const { applicationInfo, eventInfo } = payload;
        pushToDL({
            event: events.OFFER_LIST_SORT,
            applicationInfo,
            eventInfo,
        });
    },
    pushOfferLinkClick(payload: IOfferLinkClick): void {
        const { applicationInfo, elementInfo, offerInfo } = payload;
        pushToDL({
            event: events.OFFER_LINK_CLICK,
            applicationInfo,
            elementInfo,
            offerInfo,
        });
    },

    // remove this when inbox is moved to monorepo
    pushAutogiroAccept(eventInfo: IEventInfo): void {
        pushToDL({ event: events.AUTOGIRO_ACCEPT, eventInfo });
    },

    pushMessagePromptClicked(payload: IMessagePromtClick): void {
        pushToDL({ event: events.MESSAGE_PROMPT_CLICK, ...payload });
    },

    pushOfferListShowDetails(payload: IOfferListShowDetails): void {
        pushToDL({ event: events.OFFER_LIST_SHOW_DETAILS, ...payload });
    },

    pushOtherPartnersPromptClick(payload: IOtherPartnersPromptClick): void {
        pushToDL({ event: events.OTHER_PARTNERS_PROMPT_CLICK, ...payload });
    },
};
