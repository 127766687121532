import { Box, Stack, Typography } from '@lendoab/aphrodite';
import React from 'react';

export default function SbabGreenLoanInfo() {
    return (
        <Box>
            <Typography.Title level={4} marginBottom="xs" color="gray-10">
                Vad är ett grönt bolån?
            </Typography.Title>
            <Stack fluid space="medium">
                <Typography.Body marginBottom="medium">
                    Ett grönt bolån innebär att du som bor i en energieffektiv bostad kan få rabatt på din ränta. För
                    att du ska beviljas rabatten måste du ha en giltig energideklaration för klass A, B eller C.
                </Typography.Body>

                <Stack component="ul" paddingLeft="xl">
                    <li>Du med energiklass A eller B får 0,10 procentenheter i ränteavdrag.</li>
                    <li>Du med energiklass C får 0,05 procentenheter i ränteavdrag.</li>
                </Stack>

                <Typography.Body>
                    Vill du veta mer? Ring våra handläggare på
                    <Typography.Link
                        marginLeft="base"
                        style={{ whiteSpace: 'nowrap' }}
                        fontWeight="medium"
                        href="tel:0771 13 13 10"
                    >
                        0771 13 13 10
                    </Typography.Link>
                    .
                </Typography.Body>
            </Stack>
        </Box>
    );
}
