import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { AcceptanceActions } from 'APP/actions/AcceptanceActions';
import { OptimizelyData } from 'APP/actions/actions.interfaces';
import { AuthActions } from 'APP/actions/AuthActions';
import { OfferActions } from 'APP/actions/OfferActions';
import { ApplicationConstants } from 'APP/constants/ApplicationConstants';
import { IRootState, StringMap } from 'APP/interfaces/general.interfaces';

export const getOfferAction = createAsyncThunk('offer/get', async (query: string, thunkAPI) => {
    try {
        const res = await OfferActions.get(query);
        return res.data;
    } catch (err: unknown) {
        Sentry.captureException(err);
        return thunkAPI.rejectWithValue(err);
    }
});

export const postOfferAction = createAsyncThunk(
    'offer/post',
    async (
        {
            optimizelyData,
            productRequirements,
            postApplicationTracking,
        }: {
            optimizelyData: OptimizelyData;
            productRequirements: StringMap;
            postApplicationTracking: () => void;
        },
        thunkAPI
    ) => {
        const state = thunkAPI.getState() as IRootState;
        const application = state.applicationsSlice.relevantApplication;
        const { upsells, activeOffer } = state.offerSlice;
        const userResponses = [{ id: activeOffer.id, type: 'loan_offer' }];
        if (upsells.loanInsurance.userAccepted)
            userResponses.push({ id: upsells.loanInsurance.id, type: 'loan_insurance' });
        if (upsells.safetyInsurance.userAccepted)
            userResponses.push({ id: upsells.safetyInsurance.id, type: 'safety_insurance' });

        try {
            const res = await AcceptanceActions.post({
                application_id: application.id,
                application_type: ApplicationConstants.TYPE.PRIVATE,
                revision: application.revision,
                responses: userResponses,
                parameters: { ...translateValuesToRequest(productRequirements) },
                extra_parameters: {
                    optimizely: optimizelyData,
                },
            });
            postApplicationTracking();
            return res;
        } catch (err: unknown) {
            Sentry.captureException(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const postOfferWithBankIdAction = createAsyncThunk('offerWithBankID/post', async (ssn: string, thunkAPI) => {
    try {
        const res = await AuthActions.startBankID(ssn);
        return res.data;
    } catch (err: unknown) {
        Sentry.captureException(err);
        return thunkAPI.rejectWithValue(err);
    }
});
export const getPollBankIdAction = createAsyncThunk('offerWithBankID/get', async (reference: string, thunkAPI) => {
    try {
        const res = await AuthActions.getBankIDStatus(reference);
        return res;
    } catch (err: unknown) {
        Sentry.captureException(err);
        return thunkAPI.rejectWithValue(err);
    }
});

interface Parameters {
    loan_purpose?: string;
    email?: string;
    account_number?: string;
    contact_person?: string;
    clearing_number?: string;
}

function translateValuesToRequest(productRequirements: StringMap): Parameters {
    const parameters: Parameters = {};
    for (const [key, value] of Object.entries(productRequirements)) {
        switch (key) {
            case 'loanPurpose':
                parameters['loan_purpose'] = value;
                continue;
            case 'email':
                parameters['email'] = value;
                continue;
            case 'accountNumber':
                parameters['account_number'] = value;
                continue;
            case 'contactPerson':
                parameters['contact_person'] = value;
                continue;
            case 'clearingNumber':
                parameters['clearing_number'] = value;
                continue;

            default:
                break;
        }
    }
    return parameters;
}
