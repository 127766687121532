import {
    Box,
    Button,
    Column,
    Columns,
    ContentBlock,
    EmailIcon,
    Inline,
    PhoneIcon,
    SectionBlock,
    Stack,
    Text,
} from '@lendoab/aphrodite';
import {
    CookieBanner,
    CookieBannerAccordion,
    CookieBannerStep1,
    CookieBannerStep2,
    useCookieBanner,
} from '@lendoab/use-cookie-banner';
import { Analytics } from 'APP/Analytics';
import externalRoutes from 'APP/config/externalRoutes';
import { lendoDkLogo, lendoNoLogo, lendoSeLogo } from 'APP/config/logoSources';
import { CONSENT_COOKIE_NAME, SEEN_COOKIE_NAME } from 'APP/constants/CookieConstants';
import { CustomerSupportConstants } from 'APP/constants/CustomerSupportConstants';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { PrivateUserAgreementModal } from '../Modals/UserAgreementModal';
import styles from './Footer.module.scss';

export function Footer(props) {
    const date = new Date();
    return (
        <Box {...props}>
            <SectionBlock backgroundColor="dark">
                <FooterList marginBottom="3xl" />

                <FooterDivider marginBottom="3xl" />

                <Box display="flex" flexDirection="column" alignItems={'center'}>
                    <FooterImages />

                    <Text marginTop="2xl" align="center" color="light" size="xs">
                        © {date.getFullYear()} Lendo AB / Org. nr:{' '}
                        <Text component="span" color="light" size="xs">
                            556515
                        </Text>
                        -
                        <Text component="span" color="light" size="xs">
                            1627
                        </Text>{' '}
                        / Västra Järnvägsgatan 21, 111 64 Stockholm
                    </Text>
                </Box>
            </SectionBlock>
        </Box>
    );
}

function resolveCookieDomain() {
    if (typeof window === 'undefined') {
        return undefined;
    } else if (window.location.host.indexOf('stage.lendo.se') !== -1) {
        return '.stage.lendo.se';
    } else if (window.location.host.indexOf('lendo.se') !== -1) {
        return '.lendo.se';
    } else {
        return undefined;
    }
}

function FooterList(props) {
    const { status, show, acceptAll, goToStep2, acceptCurrent, getAccordionProps } = useCookieBanner({
        cookieName: CONSENT_COOKIE_NAME,
        seenCookieName: SEEN_COOKIE_NAME,
        domain: resolveCookieDomain(),
        consentLevels: [
            { name: 'necessary', initial: true, readonly: true },
            { name: 'product', initial: true, readonly: false },
            { name: 'marketing', initial: false, readonly: false },
        ],
        onAcceptAll(levels, step) {
            if (step === 2) {
                Analytics.pushConsentInteraction({
                    action: 'accept all',
                    label: 'marketing',
                });
            } else {
                Analytics.pushConsentInteraction({
                    action: 'approve',
                    label: 'marketing',
                });
            }
        },
        onAcceptCurrent(levels) {
            Analytics.pushConsentInteraction({
                action: 'save settings',
                label: Object.values(levels)
                    .reverse()
                    .find(l => l.checked).name,
            });
        },
        onAccordionToggle(name, expand) {
            Analytics.pushConsentInteraction({
                action: expand ? 'expand' : 'collapse',
                label: name,
            });
        },
    });

    return (
        <Fragment>
            <CookieBannerComponent
                status={status}
                acceptAll={acceptAll}
                goToStep2={goToStep2}
                acceptCurrent={acceptCurrent}
                getAccordionProps={getAccordionProps}
            />
            <Box className={styles.columns} marginX={['none', 'auto', 'none']} {...props}>
                <Columns space="medium" collapseBelow="tablet">
                    <Column display={['block', 'none', 'block']}>
                        <Stack space="medium">
                            <FooterListHeader>Våra tjänster</FooterListHeader>

                            <FooterListItem component="a" href="https://www.lendo.se/privatlan">
                                Privatlån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/samla-lan-krediter">
                                Samla lån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/billan">
                                Billån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/blancolan">
                                Blancolån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/foretagslan">
                                Företagslån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/batlan">
                                Båtlån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/trygghetsforsakring">
                                Trygghetsförsäkring
                            </FooterListItem>
                        </Stack>
                    </Column>

                    <Column display={['block', 'none', 'block']}>
                        <Stack space="medium">
                            <FooterListHeader>Lär dig mer</FooterListHeader>

                            <FooterListItem component="a" href="https://www.lendo.se/allt-om-lan">
                                Allt om lån
                            </FooterListItem>
                            <FooterListItem component="a" href="https://www.lendo.se/lanebloggen">
                                Lånebloggen
                            </FooterListItem>
                        </Stack>
                    </Column>

                    <Column display={['block', 'none', 'block']}>
                        <Stack space="medium">
                            <FooterListHeader>Kontakt</FooterListHeader>

                            <Inline space="xs">
                                <PhoneIcon color="lightGray" />
                                <FooterListItem component="a" href={`tel:${CustomerSupportConstants.rawPhoneNumber}`}>
                                    {CustomerSupportConstants.prettyPhoneNumber}
                                </FooterListItem>
                            </Inline>

                            <Inline space="xs">
                                <EmailIcon color="lightGray" />
                                <FooterListItem component="a" href="mailto:kundservice@lendo.se">
                                    kundservice@lendo.se
                                </FooterListItem>
                            </Inline>

                            <FooterListItem component="a" href="https://www.lendo.se/kundtjanst">
                                Kundtjänst
                            </FooterListItem>

                            <FooterListItem
                                component="a"
                                href="https://www.lendo.se/privacy-policy/personuppgiftspolicy"
                            >
                                Personuppgiftspolicy
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/privacy-policy/cookies">
                                Cookies
                            </FooterListItem>

                            <Text
                                backgroundColor="transparent"
                                cursor="pointer"
                                padding="none"
                                borderWidth="none"
                                color="lightGray"
                                size="xs"
                                component="button"
                                onClick={show}
                            >
                                Ändra val av cookies
                            </Text>
                        </Stack>
                    </Column>

                    <Column display={['block', 'none', 'block']}>
                        <Stack space="medium">
                            <Text color="light" weight="bold" size="medium">
                                Lendo
                            </Text>

                            <FooterListItem component="a" href="https://www.lendo.se/om-lendo">
                                Om Lendo
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/om-lendo/karriar">
                                Karriär
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/partners">
                                Våra partners
                            </FooterListItem>

                            <PrivateUserAgreementModal>
                                <FooterListItem component="button">Användaravtal</FooterListItem>
                            </PrivateUserAgreementModal>
                        </Stack>
                    </Column>

                    {/*TABLET*/}
                    <Column display={['none', 'block', 'none']} paddingLeft="none">
                        <Stack space="xl">
                            <FooterListHeader>Våra tjänster</FooterListHeader>

                            <FooterListItem component="a" href="https://www.lendo.se/privatlan">
                                Privatlån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/samla-lan-krediter">
                                Samla lån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/billan">
                                Billån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/blancolan">
                                Blancolån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/foretagslan">
                                Företagslån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/batlan">
                                Båtlån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/trygghetsforsakring">
                                Trygghetsförsäkring
                            </FooterListItem>

                            <FooterListHeader>Lär dig mer</FooterListHeader>

                            <FooterListItem component="a" href="https://www.lendo.se/allt-om-lan">
                                Allt om lån
                            </FooterListItem>
                            <FooterListItem component="a" href="https://www.lendo.se/lanebloggen">
                                Lånebloggen
                            </FooterListItem>
                        </Stack>
                    </Column>

                    <Column display={['none', 'block', 'none']}>
                        <Stack space="xl">
                            <FooterListHeader>Kontakt</FooterListHeader>

                            <Inline space="xs">
                                <PhoneIcon color="lightGray" />
                                <FooterListItem component="a" href={`tel:${CustomerSupportConstants.rawPhoneNumber}`}>
                                    {CustomerSupportConstants.prettyPhoneNumber}
                                </FooterListItem>
                            </Inline>

                            <Inline space="xs">
                                <EmailIcon color="lightGray" />
                                <FooterListItem component="a" href="mailto:kundservice@lendo.se">
                                    kundservice@lendo.se
                                </FooterListItem>
                            </Inline>

                            <FooterListItem component="a" href="https://www.lendo.se/kundtjanst">
                                Kundtjänst
                            </FooterListItem>

                            <FooterListItem
                                component="a"
                                href="https://www.lendo.se/privacy-policy/personuppgiftspolicy"
                            >
                                Personuppgiftspolicy
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/privacy-policy/cookies">
                                Cookies
                            </FooterListItem>

                            <Text
                                backgroundColor="transparent"
                                cursor="pointer"
                                padding="none"
                                borderWidth="none"
                                color="lightGray"
                                size="xs"
                                component="button"
                                onClick={show}
                            >
                                Ändra val av cookies
                            </Text>

                            <Text color="light" weight="bold" size="medium">
                                Lendo
                            </Text>

                            <FooterListItem component="a" href="https://www.lendo.se/om-lendo">
                                Om Lendo
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/om-lendo/karriar">
                                Karriär
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/partners">
                                Våra partners
                            </FooterListItem>

                            <PrivateUserAgreementModal>
                                <FooterListItem component="button">Användaravtal</FooterListItem>
                            </PrivateUserAgreementModal>
                        </Stack>
                    </Column>
                </Columns>
            </Box>
        </Fragment>
    );
}

function FooterListHeader({ children, ...rest }) {
    return (
        <Text color="light" weight="bold" size="medium" {...rest}>
            {children}
        </Text>
    );
}

FooterListHeader.propTypes = {
    children: PropTypes.node.isRequired,
};

function FooterListItem({ children, component, href, ...rest }) {
    function onItemClicked() {
        Analytics.pushUIInteraction({
            type: 'click',
            category: 'footer',
            title: typeof children === 'string' ? children : 'Footer List Item',
        });
    }

    if (component === 'button') {
        return (
            <Text
                backgroundColor="transparent"
                cursor="pointer"
                padding="none"
                borderWidth="none"
                color="lightGray"
                size="xs"
                component={component}
                href={href}
                onClick={onItemClicked}
                {...rest}
            >
                {children}
            </Text>
        );
    }

    if (component === 'a') {
        return (
            <Text
                backgroundColor="transparent"
                cursor="pointer"
                padding="none"
                borderWidth="none"
                color="lightGray"
                size="xs"
                component={component}
                href={href}
                onClick={onItemClicked}
                {...rest}
            >
                {children}
            </Text>
        );
    }

    return (
        <Link href={href}>
            <Text
                backgroundColor="transparent"
                cursor="pointer"
                padding="none"
                borderWidth="none"
                color="lightGray"
                size="xs"
                onClick={onItemClicked}
                {...rest}
            >
                {children}
            </Text>
        </Link>
    );
}

FooterListItem.propTypes = {
    children: PropTypes.node.isRequired,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    href: PropTypes.string,
};

function FooterDivider(props) {
    return (
        <Box
            style={{ height: '1px', backgroundColor: '#9295A7' }}
            marginX={['none', 'auto', 'none']}
            fluid
            className={styles.columns}
            {...props}
        />
    );
}

function FooterImages() {
    return (
        <Columns className={styles.columns} space="2xl" collapseBelow="desktop">
            <Column display={['none', 'none', 'flex']}>
                <Box component="a" href="/">
                    <Box
                        loading="lazy"
                        component="img"
                        className={styles.footerLogo}
                        alt="lendo se"
                        src={lendoSeLogo}
                    />
                </Box>
            </Column>
            <Column display={['none', 'none', 'flex']}>
                <Box component="a" href={externalRoutes.lendoNo} rel="noreferrer" target="_blank">
                    <Box
                        loading="lazy"
                        component="img"
                        className={styles.footerLogo}
                        alt="lendo no"
                        src={lendoNoLogo}
                    />
                </Box>
            </Column>
            <Column display={['none', 'none', 'flex']}>
                <Box component="a" href={externalRoutes.lendoDk} rel="noreferrer" target="_blank">
                    <Box
                        loading="lazy"
                        component="img"
                        className={styles.footerLogo}
                        alt="lendo dk"
                        src={lendoDkLogo}
                    />
                </Box>
            </Column>

            <Columns space="2xl">
                <Column>
                    <Stack space="small" display={['flex', 'flex', 'none']}>
                        <Box component="a" href="/">
                            <Box
                                loading="lazy"
                                component="img"
                                className={styles.footerLogo}
                                alt="lendo se"
                                src={lendoSeLogo}
                            />
                        </Box>
                        <Box component="a" href={externalRoutes.lendoDk} rel="noreferrer" target="_blank">
                            <Box
                                loading="lazy"
                                component="img"
                                className={styles.footerLogo}
                                alt="lendo dk"
                                src={lendoDkLogo}
                            />
                        </Box>
                        <Box component="a" href={externalRoutes.lendoNo} rel="noreferrer" target="_blank">
                            <Box
                                loading="lazy"
                                component="img"
                                className={styles.footerLogo}
                                alt="lendo no"
                                src={lendoNoLogo}
                            />
                        </Box>
                    </Stack>
                </Column>
            </Columns>
        </Columns>
    );
}

function CookieBannerComponent({ status, acceptAll, goToStep2, acceptCurrent, getAccordionProps }) {
    function onSettingsClick() {
        Analytics.pushConsentInteraction({
            action: 'settings',
        });
        goToStep2();
    }

    function trackCookieClick() {
        Analytics.pushConsentInteraction({
            action: 'cookie policy link',
            label: status === 'step_1' ? 'main' : 'settings',
        });
    }

    function trackPersonalDataPolicyClick() {
        Analytics.pushConsentInteraction({
            action: 'personal data policy link',
            label: status === 'step_1' ? 'main' : 'settings',
        });
    }

    return (
        <CookieBanner status={status} className={styles.cookieWrapper}>
            <ContentBlock>
                <CookieBannerStep1>
                    <Columns collapseBelow="desktop" space={['medium', 'medium', '26xl']}>
                        <Column>
                            <Text marginBottom="medium" size="small" color="white">
                                Vi använder cookies
                            </Text>
                            <Text size="xxs" color="white">
                                Lendo samlar in data genom cookies för att leverera vår tjänst, förbättra produkten och
                                för att genomföra relevant marknadsföring av Lendos tjänster genom tredje parter. Du kan
                                själv kontrollera dina inställningar genom att välja inställningar. Läs vår{' '}
                                <Text
                                    component="a"
                                    cursor="pointer"
                                    style={{ textDecoration: 'underline' }}
                                    size="xxs"
                                    color="white"
                                    onClick={trackCookieClick}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.lendo.se/privacy-policy/cookies"
                                >
                                    cookiepolicy
                                </Text>{' '}
                                och{' '}
                                <Text
                                    component="a"
                                    cursor="pointer"
                                    style={{ textDecoration: 'underline', color: '#fff' }}
                                    size="xxs"
                                    color="white"
                                    onClick={trackPersonalDataPolicyClick}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.lendo.se/privacy-policy/personuppgiftspolicy"
                                >
                                    personuppgiftspolicy
                                </Text>
                            </Text>
                        </Column>
                        <Column width={4}>
                            <Button onClick={acceptAll} size="small" marginBottom="medium" fluid primary>
                                Jag godkänner
                            </Button>

                            <Button
                                onClick={onSettingsClick}
                                size="small"
                                fluid
                                style={{ backgroundColor: 'transparent', color: 'white' }}
                            >
                                Inställningar
                            </Button>
                        </Column>
                    </Columns>
                </CookieBannerStep1>

                <CookieBannerStep2>
                    <Columns space={['medium', 'medium', '26xl']} collapseBelow="desktop">
                        <Column>
                            <Text marginBottom="xs" size="small" color="white">
                                Inställningar för cookies
                            </Text>

                            <Text marginBottom="small" size="xxs" color="white">
                                Här kan du själv välja vilka cookies som får delas med tredje part genom att kryssa i de
                                kategorier som du godkänner. Vill du läsa mer kan du klicka dig vidare till vår{' '}
                                <Text
                                    component="a"
                                    cursor="pointer"
                                    size="xxs"
                                    style={{ textDecoration: 'underline' }}
                                    color="white"
                                    onClick={trackCookieClick}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.lendo.se/privacy-policy/cookies"
                                >
                                    cookiepolicy
                                </Text>
                            </Text>

                            <CookieBannerAccordion
                                {...getAccordionProps('necessary')}
                                title="Nödvändiga"
                                marginBottom="xs"
                            >
                                <Text color="white" size="xxs">
                                    Dessa cookies är nödvändiga för att vår webbsida ska fungera korrekt, därför går de
                                    inte att stänga av. Det är exempelvis cookies som känner av var du befinner dig i
                                    ansökningsprocessen eller som identifierar dig när du loggar in på Mina sidor.
                                </Text>
                            </CookieBannerAccordion>

                            <CookieBannerAccordion
                                {...getAccordionProps('product')}
                                title="Analys & Produktutveckling"
                                marginBottom="xs"
                            >
                                <Text color="white" size="xxs">
                                    Dessa cookies ger oss information om hur vår webbsida används och ger oss möjlighet
                                    att förbättra produkten så att du som användare får bästa möjliga
                                    användarupplevelse.
                                </Text>
                            </CookieBannerAccordion>

                            <CookieBannerAccordion
                                {...getAccordionProps('marketing')}
                                title="Marknadsföring"
                                marginBottom="xs"
                            >
                                <Text color="white" size="xxs">
                                    Lendo använder cookies för marknadsföring. Genom att bocka i cookies för
                                    marknadsföring samtycker du till att Lendo delar din data till Facebook, Bing och
                                    Googles plattformar för att förbättra samt genomföra relevant marknadsföring av
                                    Lendos tjänster. De kategorier av personuppgifter vi delar är; information om din
                                    aktivitet på vår webbsida och din webbläsare detta används för att vi ska kunna visa
                                    riktad annonsering till dig på andra webbplatser.
                                </Text>
                            </CookieBannerAccordion>
                        </Column>
                        <Column width={4}>
                            <Button onClick={acceptAll} size="small" marginBottom="medium" fluid primary>
                                Tillåt alla
                            </Button>

                            <Button
                                onClick={acceptCurrent}
                                size="small"
                                fluid
                                style={{ backgroundColor: 'transparent', color: 'white' }}
                            >
                                Spara mina val
                            </Button>
                        </Column>
                    </Columns>
                </CookieBannerStep2>
            </ContentBlock>
        </CookieBanner>
    );
}

CookieBannerComponent.propTypes = {
    status: PropTypes.string,
    acceptAll: PropTypes.func,
    goToStep2: PropTypes.func,
    acceptCurrent: PropTypes.func,
    getAccordionProps: PropTypes.func,
};
