import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    pushRevisionCreated,
    pushRevisionRejected,
    pushRevisionSubmitted,
} from 'APP/components/CustomerRevision/CustomerRevisionHelpers';
import { States } from 'APP/components/CustomerRevision/enums/States';
import { ApiStatus } from 'APP/constants/ApiStatusConstants';
import { IApplication } from 'APP/interfaces/applications.interfaces';

import { revisionInitialState } from './revisionInitialState';
import {
    fetchSmartRevisionDataAction,
    postRevisionAction,
    postSmartRevisionDataEventAction,
} from './revisionSliceActions';

const initialState = revisionInitialState;

interface IntializeLoanValues {
    hasSmartData?: boolean;
    totalLoanAmount?: number;
    solveLoanAmount?: number;
    amortizeLength?: number;
    accommodationCost?: number;
    accommodationType?: string;
    application: IApplication;
    hasInitiatedLoanValues?: boolean;
    hasCoApplicantInitally?: boolean;
}

interface LoanDetails {
    totalLoanAmount: number;
    amortizeLength: number;
    solveLoanAmount: number;
    accommodationCost: number;
}
interface CoApplicantInfo {
    ['coApplicant.email']: string;
    ['coApplicant.phone']: string;
    ['coApplicant.ssn']: string;
    ['coApplicant.civilState']: string;
    ['coApplicant.numberOfChildren']: string;
}

const stepNames: Record<number, string> = {
    1: 'index',
    2: 'personalInformation',
    3: 'accommodation',
    4: 'employment',
    5: 'confirmationPage',
};

const revisionSlice = createSlice({
    name: 'revision',
    initialState,
    extraReducers: builder => {
        builder.addCase(postRevisionAction.fulfilled, (state, action) => {
            state.statusComponentShowing = true;
            state.status = States.SUCCESS;
            state.stepName = 'confirmationPage';
            // Should contain application data for the old application
            pushRevisionSubmitted(state);
            if (action.payload) {
                state.application = action.payload;
            }
            // Should contain application data for the new application
            pushRevisionCreated(state);
        }),
            builder.addCase(fetchSmartRevisionDataAction.rejected, state => {
                state.smartRevisionData.apiStatus = ApiStatus.FAILED;
            });
        builder.addCase(fetchSmartRevisionDataAction.fulfilled, (state, action) => {
            state.smartRevisionData.apiStatus = ApiStatus.COMPLETED;

            if (action.payload) {
                state.smartRevisionData.recommendedTotalLoanAmount = action.payload.recommended_total_loan_amount;
                state.smartRevisionData.recommendedAmountToCollect = action.payload.recommended_amount_to_collect;
                state.smartRevisionData.recommendedAmortizeLength = action.payload.recommended_amortize_length;
            }
        }),
            builder.addCase(postSmartRevisionDataEventAction.rejected, state => {
                state.smartRevisionData.apiPostEventStatus = ApiStatus.FAILED;
            });
        builder.addCase(postSmartRevisionDataEventAction.fulfilled, state => {
            state.smartRevisionData.apiPostEventStatus = ApiStatus.COMPLETED;
        }),
            builder.addCase(postRevisionAction.rejected, (state, action) => {
                state.status = States.REJECTED;
                state.statusComponentShowing = true;
                state.stepName = 'confirmationPage';
                pushRevisionRejected(state, {
                    errorCode: action.payload?.statusCode,
                    errorMessage: action.error.message,
                });
            });
    },
    reducers: {
        updateFormStep: (state, action: PayloadAction<number>) => {
            state.step = action.payload;
            state.stepName = stepNames[action.payload];
        },
        updateHasCoApplicant: (state, action: PayloadAction<boolean>) => {
            state.coApplicant = action.payload;
        },
        updateHasCoApplicantInitally: (state, action: PayloadAction<boolean>) => {
            state.hasCoApplicantInitally = action.payload;
        },
        updateHasInitiatedLoanValues(state, action: PayloadAction<boolean>) {
            state.hasInitiatedLoanValues = action.payload;
        },
        updateInitialLoanValues(state, action: PayloadAction<IntializeLoanValues>) {
            const {
                totalLoanAmount,
                solveLoanAmount,
                amortizeLength,
                accommodationCost,
                accommodationType,
                application,
                hasInitiatedLoanValues,
                hasCoApplicantInitally,
            } = action.payload;
            state.totalLoanAmount = totalLoanAmount ? totalLoanAmount : 0;
            state.solveLoanAmount = solveLoanAmount ? solveLoanAmount : 0;
            state.amortizeLength = amortizeLength ? amortizeLength : 0;
            state.accommodationCost = accommodationCost ? accommodationCost : 0;
            state.accommodationType = accommodationType ? accommodationType : '';
            // set same values as initial loan values
            state.initialSolveLoanAmount = application.products[0].amount_to_collect
                ? application.products[0].amount_to_collect
                : 0;
            state.initialTotalLoanAmount = application.products[0].total_loan_amount
                ? application.products[0].total_loan_amount
                : 0;
            state.initialAmortizeLength = application.products[0].amortize_length
                ? application.products[0].amortize_length
                : 0;
            state.initialAccommodationCost = accommodationCost ? accommodationCost : 0;
            state.application = application;

            // Set other initial values based on existing application values
            state.hasInitiatedLoanValues = !!hasInitiatedLoanValues;
            state.hasCoApplicantInitally = !!hasCoApplicantInitally;
            state.customerRevisionAllowed = action.payload.application.customer_revision_allowed;
        },
        updateLoanDetails(state, action: PayloadAction<{ field: string; value: number }>) {
            const detailsField = action.payload.field as keyof LoanDetails;
            state[detailsField] = action.payload.value;
        },
        updateCoApplicantInfo(state, action: PayloadAction<{ field: string; value: string }>) {
            const detailsField = action.payload.field as keyof CoApplicantInfo;
            state[detailsField] = action.payload.value;
        },
        updateRevisionBoxShow: (state, action: PayloadAction<boolean>) => {
            state.revisionBoxShow = action.payload;
        },
        updateRevisionStatus: (state, action: PayloadAction<string>) => {
            state.status = action.payload;
        },
        updateStatusComponentShowing: (state, action: PayloadAction<boolean>) => {
            state.statusComponentShowing = action.payload;
        },
        resetState: () => {
            return initialState;
        },
    },
});
export const {
    updateFormStep,
    resetState,
    updateHasCoApplicant,
    updateHasCoApplicantInitally,
    updateHasInitiatedLoanValues,
    updateInitialLoanValues,
    updateRevisionBoxShow,
    updateLoanDetails,
    updateRevisionStatus,
    updateStatusComponentShowing,
    updateCoApplicantInfo,
} = revisionSlice.actions;
export default revisionSlice.reducer;
