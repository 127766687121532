import {
    Alert,
    BankIdIcon,
    Box,
    Button,
    Card,
    CheckCircleIcon,
    Column,
    Columns,
    InfoButton,
    Stack,
    Text,
    Tooltip,
    Typography,
    useRect,
} from '@lendoab/aphrodite';
import { isNullOrUndefined } from '@lendoab/lendose-shared';
import NextSteps from 'APP/components/Acceptances/NextSteps';
import { Divider } from 'APP/components/Divider';
import ProductLogo from 'APP/components/ProductLogo/ProductLogo';
import { ApplicantConstants } from 'APP/constants/ApplicantConstants';
import { PROPERTY_TYPE_NAMES, PROPERTY_TYPES } from 'APP/constants/MortgageConstants';
import { ApplicantHelpers } from 'APP/helpers/ApplicantHelpers';
import MortgageHelpers from 'APP/helpers/MortgageHelpers';
import { applyThousandSeparator } from 'APP/helpers/number-formatters';
import { OfferHelpers } from 'APP/helpers/OfferHelpers';
import { createPositionFn } from 'APP/helpers/TooltipHelpers';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import SbabDoubleSigningModal from './BankId/SbabDoubleSigningModal';
import SbabSigningModal from './BankId/SbabSigningModal';

const { SELF_EMPLOYMENT, PROBATIONARY_EMPLOYMENT, TEMPORARY_EMPLOYMENT, SUBSTITUTE_EMPLOYMENT, HOURLY_EMPLOYMENT } =
    ApplicantConstants.OCCUPATION.MORTGAGE_EMPLOYMENT_TYPES;

const TEMP_EMPLOYMENT_TYPES = [PROBATIONARY_EMPLOYMENT, TEMPORARY_EMPLOYMENT, SUBSTITUTE_EMPLOYMENT, HOURLY_EMPLOYMENT];

function getPropertyPrefix(propertyType) {
    if (propertyType === PROPERTY_TYPES.APARTMENT || propertyType === PROPERTY_TYPES.HOUSE) {
        return 'en';
    } else return 'ett';
}

export default function SbabLoanPromiseOfferReceipt(props) {
    const { offer, mortgageApplication } = props;

    const mainApplicant = ApplicantHelpers.mainApplicant(mortgageApplication.applicants);
    const coApplicant = ApplicantHelpers.coApplicant(mortgageApplication.applicants);

    const isOfferSignedByMainApplicant = OfferHelpers.isOfferSignedByApplicant(offer, mainApplicant.ssn);
    const isOfferSignedByCoApplicant = coApplicant && OfferHelpers.isOfferSignedByApplicant(offer, coApplicant.ssn);

    const isOfferSigned = coApplicant
        ? isOfferSignedByMainApplicant && isOfferSignedByCoApplicant
        : isOfferSignedByMainApplicant;

    if (isNullOrUndefined(offer.external_id)) {
        return <Idle offer={offer} />;
    } else if (isOfferSigned) {
        return <Signed offer={offer} application={mortgageApplication} />;
    } else {
        return <WaitingToBeSigned offer={offer} application={mortgageApplication} />;
    }
}

SbabLoanPromiseOfferReceipt.propTypes = {
    offer: PropTypes.object.isRequired,
    applicant: PropTypes.object.isRequired,
    mortgageApplication: PropTypes.object.isRequired,
};

function Signed(props) {
    const { offer, application } = props;

    const containerRef = useRef();
    const containerRect = useRect(containerRef);

    const sbabOfferAcceptedInfo = [
        'Banken behöver godkänna objektet innan kontraktsskrivning.',
        'Om befintligt boende finns ska den vara såld före utbetalning.',
        'Inkomst kan behöva styrkas vid nyttjande av lånelöfte så tänk på att vara förberedd med ett arbetsgivarintyg, dina tre senaste lönespecifikationer och en objektbeskrivning.',
    ];

    const mainApplicant = ApplicantHelpers.mainApplicant(application.applicants);
    const coApplicant = ApplicantHelpers.coApplicant(application.applicants);

    const hasAppliedWithPrivateLoan = offer?.additional_information?.sbab?.loan_promise_apply_with_unsecured_loan;

    const privateLoanAmount = MortgageHelpers.getSbabLoanPromisePrivateLoanAmount(offer.additional_information);
    const maxApprovedLoanAmount = MortgageHelpers.getSbabLoanPromiseMaxApprovedAmount(offer);
    const loanAmountWithOutPrivateLoan = offer.amount;
    const loanAmount = hasAppliedWithPrivateLoan ? maxApprovedLoanAmount : loanAmountWithOutPrivateLoan;
    const propertyPrice = hasAppliedWithPrivateLoan
        ? maxApprovedLoanAmount + application.down_payment + privateLoanAmount
        : loanAmountWithOutPrivateLoan + application.down_payment;

    return (
        <Box>
            <Typography.Title color="gray-10" level={3} fontWeight="bold" style={{ marginBottom: 16 }}>
                Du har fått ett lånelöfte!
            </Typography.Title>
            <Card borderRadius="small" paddingBottom={['medium', '2xl']} paddingTop="none" paddingX="none">
                <Box
                    fluid
                    padding="medium"
                    display="flex"
                    alignItems="center"
                    backgroundColor="blue-99"
                    borderTopLeftRadius="small"
                    borderTopRightRadius="small"
                    ref={containerRef}
                >
                    <CheckCircleIcon size="small" color="green-dark" />
                    <Typography.Body marginLeft="xs" color="gray-10">
                        Lånelöfte för {getPropertyPrefix(application.loan_promise_property.type)}{' '}
                        <Typography.Body fontWeight="bold" color="gray-10" component="span">
                            {PROPERTY_TYPE_NAMES[application.loan_promise_property.type]}
                        </Typography.Body>
                    </Typography.Body>
                </Box>
                <Stack fluid space="xs" paddingX={['xl', '2xl']} paddingTop={['xl', '2xl']}>
                    {offer?.product && <ProductLogo product={offer.product} justifyContent="center" />}
                    <Typography.Body fontWeight="medium" component="p" textAlign="center" marginTop={['xs', 'medium']}>
                        Du kan maximalt buda upp till:
                    </Typography.Body>
                    <Text size={['large', 'xl']} color="green-40" align="center" weight="bold">
                        {applyThousandSeparator(propertyPrice)} kr
                    </Text>
                </Stack>

                <Divider marginTop={['medium', 'medium', '2xl']} marginBottom={['medium', 'medium', 'xl']} />
                <Box paddingX={['medium', 'medium', '2xl']}>
                    <Columns flexWrap="wrap" space="xs">
                        <Column width={[12, 12, 4]}>
                            <Typography.Body fontWeight="medium" color="gray-10">
                                Maximalt lånebelopp
                            </Typography.Body>
                            <Typography.Body>{applyThousandSeparator(loanAmount)} kr</Typography.Body>
                        </Column>
                        <Column width={[12, 12, 4]} marginBottom={['medium', 'medium', 'none']}>
                            <Typography.Body fontWeight="medium" color="gray-10">
                                Kontantinsats
                            </Typography.Body>
                            <Typography.Body>{applyThousandSeparator(application.down_payment)} kr</Typography.Body>
                        </Column>
                        {hasAppliedWithPrivateLoan ? (
                            <Column width={[12, 12, 4]}>
                                <Stack>
                                    <Box alignItems="center" display="flex">
                                        <Typography.Body fontWeight="medium" color="gray-10" marginRight="base">
                                            Privatlån
                                        </Typography.Body>
                                        <Tooltip
                                            positionFn={createPositionFn(containerRect)}
                                            trigger={<InfoButton size="medium" color="blue-40" cursor="pointer" />}
                                        >
                                            <Typography.Body color="gray-10">
                                                Tänk på att ett privatlån är ett lån utan säkerhet vilket innebär en
                                                högre ränta än ett bolån, och därmed en högre månadskostnad. Privatlånet
                                                har även en kortare återbetalningstid på 2-10 år och är helt separat
                                                från bolånet.
                                            </Typography.Body>
                                        </Tooltip>
                                    </Box>
                                    <Typography.Body>{applyThousandSeparator(privateLoanAmount)} kr</Typography.Body>
                                </Stack>
                            </Column>
                        ) : null}
                        {application.loan_promise_property.monthly_cost ? (
                            <Column width={[12, 12, 4]}>
                                <Typography.Body fontWeight="medium" color="gray-10">
                                    Högsta månadsavgift
                                </Typography.Body>
                                <Typography.Body>
                                    {applyThousandSeparator(application.loan_promise_property.monthly_cost)} kr/mån
                                </Typography.Body>
                            </Column>
                        ) : null}
                    </Columns>
                    {maxApprovedLoanAmount < application.loan_amount && (
                        <Alert marginTop="medium" variant="info">
                            <Typography.Body color="gray-10" fontWeight="medium">
                                Du har fått ett lånelöfte av SBAB med ett belopp som är lägre än det du ansökte om. Vill
                                du få andra erbjudanden? ring till våra handläggare på
                                <Typography.Link
                                    marginLeft="base"
                                    style={{ whiteSpace: 'nowrap' }}
                                    href="tel:0771 13 13 10"
                                >
                                    0771 13 13 10{' '}
                                </Typography.Link>
                            </Typography.Body>
                        </Alert>
                    )}
                </Box>
                <Divider marginTop={['medium', 'xl']} marginBottom={['xl', '2xl']} />
                <Box paddingX={['medium', '2xl']} paddingBottom="small">
                    <Typography.Title level={5} color="gray-10" style={{ marginBottom: 8 }}>
                        Villkor
                    </Typography.Title>
                    <ul style={{ paddingLeft: '18px', margin: 0 }}>
                        <li>
                            <Typography.Body color="gray-10" paddingBottom="xs">
                                Lånelöftet är giltigt t.o.m{' '}
                                <Typography.Body fontWeight="bold" color="gray-10" component="span">
                                    {dayjs(offer.expires_at).format('LL')}
                                </Typography.Body>
                            </Typography.Body>
                        </li>
                        {sbabOfferAcceptedInfo.map((info, i) => (
                            <li key={i}>
                                <Typography.Body paddingBottom="xs" color="gray-10">
                                    {info}
                                </Typography.Body>
                            </li>
                        ))}
                        {(mainApplicant.employment.type === SELF_EMPLOYMENT ||
                            coApplicant?.employment?.type === SELF_EMPLOYMENT) && (
                            <li>
                                <Typography.Body color="gray-10" paddingBottom="xs">
                                    Som egenföretagare krävs oftast ett års aktivitet och en taxerad inkomst eller ett
                                    pågående konsultavtal med resultat- och balansrapporter.
                                </Typography.Body>
                            </li>
                        )}
                        {(TEMP_EMPLOYMENT_TYPES.includes(mainApplicant.employment.type) ||
                            TEMP_EMPLOYMENT_TYPES.includes(coApplicant?.employment?.type)) && (
                            <li>
                                <Typography.Body color="gray-10" paddingBottom="xs">
                                    Du behöver vara godkänd av Arbetslöshetskassan och en individuell bedömning sker vid
                                    vunnen budgivning.
                                </Typography.Body>
                            </li>
                        )}
                        <li>
                            <Typography.Body color="gray-10" paddingBottom="xs">
                                Tänk på att det här är ett lånelöfte, det innebär att du inte kan skriva kontrakt förrän
                                du fått ett beviljat lån.
                            </Typography.Body>
                        </li>
                    </ul>

                    <Box paddingTop="medium">
                        <Typography.Title level={5} color="gray-10" style={{ marginBottom: 8 }}>
                            Vad händer nu?
                        </Typography.Title>
                        <ol style={{ paddingLeft: '28px', margin: 0 }}>
                            <li>
                                <Typography.Body color="gray-10">
                                    Läs igenom villkoren och hämta ditt lånelöfte genom att logga in på{' '}
                                    <Typography.Link
                                        marginLeft="base"
                                        style={{ whiteSpace: 'nowrap' }}
                                        href="https://www.sbab.se"
                                    >
                                        www.sbab.se
                                    </Typography.Link>
                                </Typography.Body>
                            </li>
                            <li>
                                <Typography.Body color="gray-10">Börja att buda på ditt drömboende!</Typography.Body>
                            </li>
                        </ol>
                    </Box>
                    <Alert marginTop={['medium', 'medium', 'xl']} variant="info">
                        <Typography.Body color="gray-10" fontWeight="medium">
                            När du vunnit en budgivning är det viktigt att du får bostaden godkänd och lånet beviljat
                            innan du skriver på kontraktet. Ring oss på
                            <Typography.Link
                                marginLeft="base"
                                style={{ whiteSpace: 'nowrap' }}
                                href="tel:0771 13 13 10"
                            >
                                0771 13 13 10{' '}
                            </Typography.Link>
                            för att få snabbare hjälp med din bolåneansökan.
                        </Typography.Body>
                    </Alert>
                </Box>
                <Divider marginTop={['xl', '2xl']} marginBottom={['medium', '2xl']} />
                <Box paddingX={['medium', '2xl']}>
                    <Button
                        type="button"
                        fluid
                        primary
                        href="https://www.lendo.se/bolan/lan-ny-bostad"
                        component="a"
                        target="_blank"
                        marginBottom="medium"
                    >
                        Ansök om nytt bolån
                    </Button>
                    <Typography.Caption color="gray-10">
                        Lånelöftet förmedlas av Lendo AB, och långivaren är SBAB.
                    </Typography.Caption>
                </Box>
            </Card>
        </Box>
    );
}

Signed.propTypes = {
    offer: PropTypes.object.isRequired,
    application: PropTypes.object.isRequired,
};

function WaitingToBeSigned(props) {
    const { offer, application } = props;
    const { reload } = useRouter();

    const coApplicant = ApplicantHelpers.coApplicant(application.applicants);

    const [openDoubleSigningModal, setOpenDoubleSigningModal] = useState(false);
    const [openSigningModal, setOpenSigningModal] = useState(false);
    const [selectedApplicantForSigning, setSelectedApplicantForSigning] = useState(false);

    return (
        <Box>
            <Card borderRadius="small" paddingY={['medium', '2xl']} paddingX="none">
                <Stack fluid space="xs" paddingX={['medium', '2xl']}>
                    {offer?.product && <ProductLogo product={offer.product} justifyContent="center" />}
                    <Typography.Title color="gray-10" level={4} align="center" style={{ marginBottom: 0 }}>
                        Signera för att få ett besked om lånelöfte.
                    </Typography.Title>
                </Stack>

                <Divider marginTop={['xl', '2xl']} marginBottom={['xl', '2xl']} />

                <Stack fluid space={['xl', '2xl']} paddingX={['medium', '2xl']}>
                    <NextSteps
                        steps={[
                            coApplicant
                                ? 'Du och din medsökande behöver signera med BankID nedan.'
                                : 'Du behöver signera med BankID nedan.',
                            'Få svar direkt här på Mina sidor om SBAB kan erbjuda dig ett lånelöfte eller inte.',
                        ]}
                    />

                    <Alert variant="info">
                        {coApplicant
                            ? 'För att se om du kan få ett lånelöfte av SBAB behöver du, och din medsökande, signera nedan inom 3 dagar.'
                            : 'För att se om du kan få ett lånelöfte av SBAB behöver du signera nedan inom 3 dagar.'}
                    </Alert>
                </Stack>

                <>
                    <Divider marginTop={['xl', 'xl', '2xl']} />
                    <Box marginTop={['medium', 'medium', '2xl']} marginX={['medium', 'medium', '2xl']}>
                        <Button fluid primary onClick={() => setOpenDoubleSigningModal(true)}>
                            <Typography.Body color="white" fontWeight="bold">
                                Signera hos SBAB
                            </Typography.Body>
                            <BankIdIcon size="small" />
                        </Button>
                    </Box>
                </>
            </Card>

            {openDoubleSigningModal && (
                <SbabDoubleSigningModal
                    open={openDoubleSigningModal}
                    onClose={() => setOpenDoubleSigningModal(false)}
                    offer={offer}
                    applicants={application.applicants}
                    onClick={selectedApplicant => {
                        setSelectedApplicantForSigning(selectedApplicant);
                        setOpenSigningModal(true);
                        setOpenDoubleSigningModal(false);
                    }}
                />
            )}

            {openSigningModal && selectedApplicantForSigning && (
                <SbabSigningModal
                    open={openSigningModal}
                    applicant={selectedApplicantForSigning}
                    offer={offer}
                    onClose={() => setOpenSigningModal(false)}
                    onSuccess={() => {
                        setOpenSigningModal(false);
                        reload();
                    }}
                />
            )}
        </Box>
    );
}

WaitingToBeSigned.propTypes = {
    offer: PropTypes.object.isRequired,
    application: PropTypes.object.isRequired,
};

function Idle(props) {
    const { offer } = props;

    return (
        <Box>
            <Card borderRadius="small" paddingY={['medium', '2xl']} paddingX="none">
                <Stack fluid space="xs" paddingX={['medium', '2xl']}>
                    {offer?.product && <ProductLogo product={offer.product} justifyContent="center" />}
                    <Typography.Title color="gray-10" level={4} align="center" style={{ marginBottom: 0 }}>
                        Du har valt att gå vidare med SBAB!
                    </Typography.Title>
                </Stack>

                <Divider marginTop={['xl', '2xl']} marginBottom={['xl', '2xl']} />

                <Stack fluid space={['xl', '2xl']} paddingX={['medium', '2xl']}>
                    <Typography.Body>
                        Just nu behöver du inte göra något. I nästa steg behöver du signera med BankID för att få svar
                        på om SBAB kan erbjuda dig ett lånelöfte eller inte. Vi kommer att kontakta dig via e-post eller
                        telefon när det är dags.
                    </Typography.Body>

                    <Typography.Body>
                        Har du frågor angående din ansökan? Kontakta Lendos kundtjänst på
                        <Typography.Link marginLeft="base" style={{ whiteSpace: 'nowrap' }} href="tel:0771 13 13 10">
                            0771 - 13 13 10
                        </Typography.Link>
                        .
                    </Typography.Body>
                </Stack>
            </Card>
        </Box>
    );
}

Idle.propTypes = {
    offer: PropTypes.object.isRequired,
};
